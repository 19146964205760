import React, { Component } from 'react';
import '../../css/lampDetails.css'
import LampDetails from './LampDetails';


class LampGroupDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}
	render() {
		const { devices } = this.props
		const device1 = devices[0];
		const device2 = devices[1];

		return (
			<div className='detail-comtainer'>

				{device1 && <LampDetails device={device1} groups={[]} pairedDevice={device2} />}
				{device2 && <LampDetails device={device2} groups={[]} pairedDevice={device1} />}

			</div>
		);
	}
}

export default LampGroupDetails;