import React, { Component } from 'react';
import '../../css/menuSection.css'
var api = require("../../../utils/api-v2");


class GroupSection extends Component {
	state = { extended: false, addingDevice: false, deviceName: "" }

	addLampClicked = () => {
		const { group, onRefreshInfo, deviceType } = this.props
		const { deviceName } = this.state
		if (deviceName !== "") {
			api.addDevcie(deviceName, deviceType ?? "light", group._id);
			onRefreshInfo();
		}

		this.setState({ addingDevice: false });
	}

	onGroupClicked = () => {
		const { group, onGroupDetailsClicked } = this.props;
		const { extended } = this.state
		this.setState({ extended: !extended })
		if (!extended) { onGroupDetailsClicked(group._id) }
	}

	render() {
		const { extended, addingDevice } = this.state
		const { group, onClick } = this.props;

		if (extended) {
			return (<div>
				<div className="d-flex">
					<div className="menu-item flex-grow-1" onClick={this.onGroupClicked}>{group.name}</div>
					{/* {this.renderMoreButton()} */}
				</div>
				<div className="sub-menu">
					{group.devices.map((dev) => <div key={dev._id}>{this.renderDevice(dev)}</div>)}
					{addingDevice ? <div className="d-flex">
						<input type="text" placeholder="Nombre" onChange={(e) => { this.setState({ deviceName: e.target.value }) }} />
						<div className="btn btn-secondary mx-1" onClick={this.addLampClicked}>OK</div>
					</div> :
						<div className="add-subsection-btn" onClick={() => { this.setState({ addingDevice: true }) }}>Crear Dispositivo</div>}

				</div>


			</div>);
		} else
			return (
				<div className="d-flex justify-content-between">
					<div className="menu-item flex-grow-1" onClick={() => { onClick(2, group._id); this.onGroupClicked() }}>{group.name}</div>
				</div>

			);
	}

	renderMoreButton() {
		const { group, onGroupDetailsClicked } = this.props;
		return (<div className="more-btn" onClick={() => { onGroupDetailsClicked(group._id) }}><img src="/img/icons/more.png" alt="more" width="15" /></div>)
	}

	renderDevice(deviceId) {
		const { deviceType } = this.props;
		if (deviceType === "boardLcd") {
			return this.renderBoardDevice(deviceId);
		}
		else return this.renderSprinklerDevice(deviceId);
	}

	renderSprinklerDevice(deviceId) {
		const { devices, onMouseOver, onDeviceClicked } = this.props;
		let dev = devices.filter((d) => d._id === deviceId)[0];
		let img = "street_lamp_off.png"
		if (dev) {

			if (!dev.connected) {
				img = "street_lamp_offline.png"
			} else if (dev.powerState === "ON" && dev.percentage === 0) {
				img = "street_lamp_error.png"
			} else if (dev.powerState === "ON") {
				img = "street_lamp_on.png"
			}

			return <div className="d-flex menu-item animate__animated animate__flipInX"
				onMouseEnter={() => { onMouseOver(dev._id) }}
				onMouseLeave={() => { onMouseOver("") }}
				onClick={() => { onDeviceClicked(dev._id) }}>
				<div><img src={"/img/icons/" + img} alt="lamp" height="30" width="30" /></div>
				<div className="grouping-2">{dev.name}</div>
			</div>
		} else return null;


	}

	renderBoardDevice(deviceId) {
		const { devices, onMouseOver, onDeviceClicked } = this.props;
		let dev = devices.filter((d) => d._id === deviceId)[0];
		let img = "board.svg"
		if (dev) {

			if (dev.alarm) {
				img = "board_alarm.svg"
			}

			return <div className="d-flex menu-item animate__animated animate__flipInX"
				onMouseEnter={() => { onMouseOver(dev._id) }}
				onMouseLeave={() => { onMouseOver("") }}
				onClick={() => { onDeviceClicked(dev._id) }}>
				<div><img src={"/img/icons/" + img} alt="lamp" height="30" width="30" /></div>
				<div className="grouping-2">{dev.name}</div>
			</div>
		} else return null;


	}
}

export default GroupSection;

