import React, { Component } from 'react';
import MenuSection from './MenuSection';
var api = require("../../../utils/api-v2");

class MapMenu extends Component {
	state = {
		menuIsOpen: false,
		addingSection: false,
		sectionName: ""
	}

	groupSelectedChanged = (level, id) => {
		const { groupSelectedChanged } = this.props
		this.setState({ addingSection: false });
		groupSelectedChanged(level, id);
	}
	render() {
		const { menuIsOpen, addingSection, sectionName } = this.state
		const { groupSelected, sections, groups, devices, groupSelectedId, onDeviceHover, onDeviceClicked, onGroupDetailsClicked, onSectionDetailsClicked, onRefreshInfo, deviceType } = this.props

		return (

			< div className="floating-menu ">

				<div className="animate__animated animate__backInLeft" onClick={() => { this.setState({ menuIsOpen: !menuIsOpen }) }}>
					<div className="menu-tab">
						<div className="mx-2">
							<img src="/img/icons/traffic_light.svg" alt="list" width="40" height="40" />
						</div>
						<div>
							<div className="grouping-0">{groupSelected[0]}</div>
							<div className="grouping-1">{groupSelected[1]}</div>
							<div className="grouping-2">{groupSelected[2]}</div>
						</div>
					</div>
				</div>
				{menuIsOpen ? <div >
					<hr />

					{sections.filter((section) => section.level === 0).map((section) =>
						<MenuSection section={section}
							sections={sections}
							groups={groups}
							devices={devices}
							groupSelectedId={groupSelectedId}
							onClick={this.groupSelectedChanged}
							onDeviceClicked={onDeviceClicked}
							onMouseOver={onDeviceHover}
							onGroupDetailsClicked={onGroupDetailsClicked}
							onSectionDetailsClicked={onSectionDetailsClicked}
							onRefreshInfo={onRefreshInfo}
							key={section._id}
							deviceType={deviceType}
						/>)}
					{addingSection ?
						<div>
							<input type="text" placeholder="Nombre" onChange={(e) => { this.setState({ sectionName: e.target.value }) }} />
							<div className="btn btn-secondary mx-1"
								onClick={async () => {
									if (sectionName !== "") {
										api.addSection(sectionName, 0, null);
										onRefreshInfo();
									}
									this.setState({ addingSection: false, sectionName: "" })
								}}>OK</div>
						</div> :
						<div className="add-section-btn" onClick={() => { this.setState({ addingSection: true }) }}>
							<div className="mx-1"><img src="/img/icons/add.svg" alt="add" height="20" width="20" /></div>
							<div>Nueva Sección</div>
						</div>}
				</div> : null}

			</div>
		)
	}
}

export default MapMenu;