import React, { Component } from 'react';
import '../../css/menuSection.css'
import GroupSection from './GroupSection';
var api = require("../../../utils/api-v2");


class MenuSection extends Component {
	state = { extended: true, addingSubsection: false, addingGroup: false, sectionName: "", groupName: "" }

	renderMoreButton() {
		const { section, onSectionDetailsClicked } = this.props;
		return (<div className="more-btn" onClick={() => { onSectionDetailsClicked(section._id) }}><img src="/img/icons/more.png" alt="more" width="15" /></div>)
	}

	addSectionClicked = () => {
		const { section, onRefreshInfo } = this.props
		const { sectionName } = this.state
		if (sectionName !== "") {
			api.addSection(sectionName, 1, section._id);
			onRefreshInfo();
		}
		this.setState({ addingSubsection: false })
	}

	addGroupClicked = () => {
		const { section, onRefreshInfo } = this.props
		const { groupName } = this.state
		if (groupName !== "") {
			api.addGroup(groupName, section._id);
			onRefreshInfo();
		}
		this.setState({ addingGroup: false })
	}

	render() {
		const { extended, addingSubsection, addingGroup } = this.state
		const { section, sections, groups, devices, onClick, onMouseOver, onDeviceClicked, groupSelectedId, onSectionDetailsClicked, onGroupDetailsClicked, onRefreshInfo, deviceType } = this.props;

		if (extended && groupSelectedId[section.level] === section._id) {
			if (section.level === 0)

				return (<div>
					<div className="d-flex">
						<div className="menu-item flex-grow-1 animate__animated animate__fadeInDown" onClick={() => {
							onClick(0, "");
							this.setState({ extended: false, addingSubsection: false })
						}}>{section.name}</div>
						{/* {this.renderMoreButton()} */}
					</div>

					{section.children.map((secId) => <div className="sub-menu">
						<MenuSection
							section={sections.filter((e) => e._id === secId)[0]}
							sections={sections}
							groups={groups}
							devices={devices}
							deviceType={deviceType}
							onClick={onClick}
							onMouseOver={onMouseOver}
							onDeviceClicked={onDeviceClicked}
							onSectionDetailsClicked={onSectionDetailsClicked}
							onGroupDetailsClicked={onGroupDetailsClicked}
							groupSelectedId={groupSelectedId}
							onRefreshInfo={onRefreshInfo}
						/>

					</div>)}

					{addingSubsection ? <div className="add-section-btn d-flex">
						<input type="text" placeholder="Nombre" onChange={(e) => { this.setState({ sectionName: e.target.value }) }} />
						<div className="btn btn-secondary mx-1" onClick={this.addSectionClicked}>OK</div>
					</div> :
						<div className="add-subsection-btn" onClick={() => { this.setState({ addingSubsection: true }) }}>Crear sub-sección</div>}

				</div>);
			if (section.level === 1)
				return (<div >
					<div className="d-flex">
						<div className="menu-item flex-grow-1 animate__animated animate__fadeInDown" onClick={() => { this.setState({ extended: false }) }}>{section.name}</div>
						{/* {this.renderMoreButton()} */}
					</div>
					<div className="sub-menu">
						{section.groups.map((grId) => {
							if (groups.filter((gr) => gr._id === grId)[0])

								return <GroupSection group={groups.filter((gr) => gr._id === grId)[0]}
									deviceType={deviceType}
									devices={devices}
									onClick={onClick}
									onGroupDetailsClicked={onGroupDetailsClicked}
									onRefreshInfo={onRefreshInfo}
									onMouseOver={onMouseOver}
									onDeviceClicked={onDeviceClicked} />
							else return null;
						}
						)}

						{addingGroup ? <div className="d-flex">
							<input type="text" placeholder="Nombre" onChange={(e) => { this.setState({ groupName: e.target.value }) }} />
							<div className="btn btn-secondary mx-1" onClick={this.addGroupClicked}>OK</div>
						</div> :
							<div className="add-subsection-btn" onClick={() => { this.setState({ addingGroup: true }) }}>Crear grupo</div>}

					</div>
				</div>);
		} else
			return (
				<div>
					<div className="d-flex">
						<div className="menu-item flex-grow-1 animate__animated animate__fadeInDown" onClick={() => {
							onClick(section.level, section._id);
							this.setState({ extended: true })
						}}>{section.name}</div>

					</div>
				</div>
			);
	}
}

export default MenuSection;

